import React, { useCallback, useMemo, useRef, useState } from "react";
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    ROOT_URL,
    SECTIONS,
    EXTEND_VIEW_CHAIN_LIST, FEATURE_FLAG_IS_PLATFORM_ENABLED,
} from '../../utils/constants';
import { lowerCaseFirstLetter } from '../../utils/helpers';
import Autocomplete from '../../components/Autocomplete';
import { getChainsByCluster } from '../../selectors/section.selectors';
import { clearChainStore } from '../../actions/chain';
import { ReactComponent as IconLogoBf } from "../../icons/bf.svg";
import httpClient from "../../utils/httpClient";
import Notification from '../../utils/notification';
import {
    AppId,
    Header,
    HeaderLogo,
    ProductLogo,
    Navigation,
    capitalize,
    Tooltip,
    startsFromInLC,
    includesInLC,
    SearchBox,
    TextInput,
    Dropdown,
    cssX,
    BodyPortal,
    markMatch,
    usePlatformFeatureFlag,
    CountriesSelect, SearchBoxNoResults
} from "@datassential/platform-ui-lib";
import cn from "classnames";

const SEARCH_MIN_LENGTH = 2;

function MainHeader(props) {
    const {
        history,
        match: {
            params: {
                0: cluster
            }
        },
    } = props;

    const [searchQuery, setSearchQuery] = useState('');
    const notification = useRef(new Notification());
    const clusterChains = useSelector(state => getChainsByCluster(state, props));
    const avaliableChainViews = useSelector(state => state.init.avaliableChainViews);
    const dispatch = useDispatch();

    const clearStore = useCallback(
        () => dispatch(clearChainStore()),
        [dispatch]
    );


    const onSelectChain = useCallback(
        (chain) => {
            const extendViewChainId = JSON.parse(sessionStorage.getItem('EXTEND_VIEW_CHAIN_ID'));

            const {
                property = '',
            } = avaliableChainViews.find(chainView => chainView.ffChainId === extendViewChainId && chainView.ffChainId === chain.id) || {};

            const chainProp = lowerCaseFirstLetter(property);
            const pathLink = `${ROOT_URL}${cluster}/chain/${chain.id}${(chainProp && chain[chainProp]) ? `/${chain[chainProp]}` : ``}/overview`;

            history.push(pathLink);

            clearStore();
            setSearchQuery('');
        },
        [cluster, avaliableChainViews, history, clearChainStore]
    );

    const sectionAccess = useMemo(
        () => ({
            cstores: window.generalConfig.access.cStores,
            restaurants: window.generalConfig.access.restaurants,
            supermarkets: window.generalConfig.access.supermarkets,
        }),
        []
    );

    const sendMessage = (event, cluster) => {
        if (sessionStorage.getItem(`SEND_ACCESS_CHAIN_TYPE_${cluster.id}`) === null)
            new httpClient(`${process.env.REACT_APP_API}InitialData/SendAccessEmail`, {
                method: 'POST',
                data: JSON.stringify({
                    chainType: cluster.id,
                })
            });

        sessionStorage.setItem(`SEND_ACCESS_CHAIN_TYPE_${cluster.id}`, true);
        notification.current.add(`Great! We’ll be in touch very shorty.`);
    }

    const navigationItems = useMemo(
        () => SECTIONS.map((cluster, index) => {
        const isClusterEnabled = (sectionAccess[cluster.systemName] === true);
        const tooltipId = `header-nav-${capitalize(cluster.systemName, true)}`;

        return {
            id: cluster.id,
            content: (
                <NavLink
                    to={{ pathname: isClusterEnabled ? `${ROOT_URL}${cluster.aliasName}` : '' }}
                    key={index}
                    activeClassName="active-accessible"
                    className={cn(
                        'MainHeader__item',
                        !isClusterEnabled && 'disabled',
                    )}
                    data-tooltip-id={tooltipId}
                >
                    {cluster.captionName}
                    {(isClusterEnabled === false) && (
                        <BodyPortal>
                            <Tooltip
                                id={tooltipId}
                                clickable
                                positionStrategy='fixed'
                            >
                                <div style={{ textAlign: "center" }}>
                                    Looks like your subscription<br/>
                                    doesn't currently include {cluster.captionName}. <br/>
                                    Click&nbsp;
                                    <button
                                        className="button button-link"
                                        onClick={(event) => sendMessage(event, cluster)}
                                    >
                                        here
                                    </button>
                                    &nbsp;to learn about getting access.
                                </div>
                            </Tooltip>
                        </BodyPortal>
                    )}
                </NavLink>
            ),
        }
    }),
        [],
    );

    const searchAutocomplete = useMemo(
        () => {
            const { chains } = clusterChains;

            if (searchQuery.trim().length < SEARCH_MIN_LENGTH) return [];

            const matchedChains = [
                ...chains.filter(i => startsFromInLC(i.name, searchQuery.trim())),
                ...chains.filter(i => !startsFromInLC(i.name, searchQuery.trim()) && includesInLC(i.name, searchQuery.trim())),
            ];

            if (matchedChains.length === 0) return undefined;

            return [
                {
                    id: 1,
                    title: '',
                    items: matchedChains.map((chain, index) => ({
                        id: chain.id,
                        content: (
                            <div
                                key={chain.id}
                                className={cn(cssX("Dropdown__item"), 'MainHeader__dropdown-item')}
                                onClick={() => onSelectChain(chain)}
                            >
                                <div dangerouslySetInnerHTML={{
                                    __html: markMatch(chain.name, searchQuery.trim()),
                                }}/>
                            </div>
                        ),
                    })),
                }
            ];
        },
        [clusterChains, searchQuery, onSelectChain],
    )

    return (
        <Header
            className="MainHeader"
            leftContent={(<>
                <NavLink to={{ pathname: ROOT_URL }} className="MainHeader__logo">
                    <HeaderLogo>
                        <ProductLogo appId={AppId.BrandPerformance}/>
                    </HeaderLogo>
                </NavLink>
                <CountriesSelect
                    options={[
                        { id: 'usa', shortName: 'USA', name: '' },
                    ]}
                    value={'usa'}
                    setValue={() => {}}
                    isDisabled
                    className={"MainHeader__countries-select"}
                    testId={"selectDEMOHeaderCountry"}
                />
            </>)}
            rightContent={
                <Navigation data={[
                    ...navigationItems,
                    {
                        id: 'search',
                        content: (
                            <SearchBox
                                tooltipId={'header-search'}
                                inputProps={{
                                    value: searchQuery,
                                    setValue: setSearchQuery,
                                    placeholder: 'Find a chain...',
                                }}
                                dropdownProps={{
                                    id: 'header-search',
                                    isOpened: searchQuery.trim().length >= SEARCH_MIN_LENGTH,
                                    className: 'MainHeader__dropdown',
                                    style: { maxHeight: 300, overflowX: 'hidden'},
                                    groups: searchAutocomplete,
                                    children: searchAutocomplete ? null : (
                                        <SearchBoxNoResults/>
                                    ),
                                }}
                            />
                        ),
                    },
                ]}/>
            }
        />
    );
}


MainHeader.propTypes = {
    clusterChains: PropTypes.object,
    alignTooltips: PropTypes.bool,
};

MainHeader.defaultProps = {
    clusterChains: {
        chains: []
    }
};

export default MainHeader;