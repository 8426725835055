import { FEATURE_FLAG_IS_PLATFORM_ENABLED, ROOT_URL } from "./utils/constants";
import React, { Fragment, useEffect, useMemo } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Direction from "./route/Direction";
import Chain from "./route/Chain";
import Rankings from "./route/Rankings";
import ButtonScrollUp from "./components/ButtonScrollUp";
import useSessionAccess from "./hooks/useSessionAccess";
import NotFound from "./route/NotFound";
import { GtmInit, PlatformNavigation, useGtm, usePlatformFeatureFlag } from "@datassential/platform-ui-lib";

const NOT_FOUND_PATH = '404';
const RESTAURANTS_PATH = 'restaurants';
const CSTORES_PATH = 'c-stores';
const SUPERMARKETS_PATH = 'grocery';
const CHAIN_LINK = 'chain/:id/:paramId?/:groupName';
const RANKINGS_LINK = 'rankings/:aliasName';

export default function Router () {
    const {
        restaurants,
        cstores,
        supermarkets,
        isLoaded,
    } = useSessionAccess();

    const routes = useMemo(
        () => {
            const result = [
                <Route
                    exact
                    key="notFound"
                    path={`${ROOT_URL}${NOT_FOUND_PATH}`}
                    component={NotFound}
                />
            ];

            const fallbackPath = restaurants
                ? RESTAURANTS_PATH
                : cstores
                    ? CSTORES_PATH
                    : supermarkets
                        ? SUPERMARKETS_PATH
                        : NOT_FOUND_PATH;

            const FallbackRedirect = () => (
                <Redirect to={`${ROOT_URL}${fallbackPath}`} />
            );

            if (isLoaded) {
                result.push(
                    <Route
                        exact
                        key="restaurants"
                        path={`${ROOT_URL}(${RESTAURANTS_PATH})/`}
                        component={restaurants ? Direction : FallbackRedirect } />
                );
                result.push(
                    <Route
                        exact
                        key="restaurants-chain"
                        path={`${ROOT_URL}(${RESTAURANTS_PATH})/${CHAIN_LINK}`}
                        component={restaurants ? Chain : FallbackRedirect } />
                );
                result.push(
                    <Route
                        exact
                        key="restaurants-ranking"
                        path={`${ROOT_URL}(${RESTAURANTS_PATH})/${RANKINGS_LINK}`}
                        component={restaurants ? Rankings : FallbackRedirect }  />
                );
                result.push(
                    <Route
                        exact
                        key="cstore"
                        path={`${ROOT_URL}(${CSTORES_PATH})/`}
                        component={cstores ? Direction : FallbackRedirect} />
                );
                result.push(
                    <Route
                        exact
                        key="cstore-chain"
                        path={`${ROOT_URL}(${CSTORES_PATH})/${CHAIN_LINK}`}
                        component={cstores ? Chain : FallbackRedirect} />
                );
                result.push(
                    <Route
                        exact
                        key="cstore-ranking"
                        path={`${ROOT_URL}(${CSTORES_PATH})/${RANKINGS_LINK}`}
                        component={cstores ? Rankings : FallbackRedirect} />
                );
                result.push(
                    <Route
                        exact
                        key="supermarkets"
                        path={`${ROOT_URL}(${SUPERMARKETS_PATH})/`}
                        component={supermarkets ? Direction : FallbackRedirect} />
                );
                result.push(
                    <Route
                        exact
                        key="supermarkets-chain"
                        path={`${ROOT_URL}(${SUPERMARKETS_PATH})/${CHAIN_LINK}`}
                        component={supermarkets ? Chain : FallbackRedirect} />
                );
                result.push(
                    <Route
                        exact
                        key="supermarkets-ranking"
                        path={`${ROOT_URL}(${SUPERMARKETS_PATH})/${RANKINGS_LINK}`}
                        component={supermarkets ? Rankings : FallbackRedirect} />
                );
                result.push(
                    <Route
                        exact
                        key="fallback"
                        path={ROOT_URL}
                        component={FallbackRedirect}
                    />
                );
                result.push(
                    <Redirect
                        key="redirect"
                        to={`${ROOT_URL}${NOT_FOUND_PATH}`}
                    />
                );
            }
            return result;
        },
        [restaurants, supermarkets, cstores, isLoaded]
    );

    return (
        <BrowserRouter basename={`${process.env.PUBLIC_URL}${ROOT_URL}`}>
            <Fragment>
                <PlatformNavigation/>
                {process.env.NODE_ENV !== 'development' && <GtmInit/>}
                <Switch>
                    {routes}
                </Switch>
                <ButtonScrollUp/>
            </Fragment>
        </BrowserRouter>
    );
}